import React, {useCallback, useContext} from 'react';

import {SettingsContext} from 'contexts/SettingsContext';
import {MigrationContext} from 'components/AppMigration/contexts';
import {MIGRATION_STAGE} from 'components/AppMigration/constants';
import {useTranslations} from 'hooks/use-translations';
import {useBoolean} from 'hooks/utils/use-boolean';
import {openLink} from 'utils/dom';

import DeleteDialogBox from 'components/AppMigration/DeleteDialogBox';
import PromptModal from 'components/PromptModal/PromptModal';

const Step1 = () => {
  const {translate} = useTranslations();
  const {organizationConfig, appName} = useContext(SettingsContext);
  const {saveMigrationStage} = useContext(MigrationContext);
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] =
    useBoolean(false);

  const migrationConfig = organizationConfig.featuresConfig.migration;

  const handleInitiateMigration = useCallback(() => {
    saveMigrationStage(MIGRATION_STAGE.STEP2);
    openLink(migrationConfig.destination.appUrl);
  }, [migrationConfig, saveMigrationStage]);

  return (
    <>
      <PromptModal
        title={translate('sdk.web.migration.steps.1.title')}
        textContent={translate('sdk.web.migration.steps.1.subtitle', [
          {
            key: '{destinationAppName}',
            value: migrationConfig.destination.appLabel,
          },
          {
            key: '{appName}',
            value: appName,
          },
        ])}
        isVisible={true}
        image={migrationConfig.images.step1Image}
        btnText={translate('sdk.web.migration.steps.1.cta', {
          key: '{destinationAppName}',
          value: migrationConfig.destination.appLabel,
        })}
        onBtnClick={handleInitiateMigration}
        moreInfo={[
          {
            text: translate('sdk.web.migration.cta.delete'),
            onClick: openDeleteDialog,
          },
          {
            text: translate('sdk.web.migration.steps.cancel'),
            onClick: () => saveMigrationStage(MIGRATION_STAGE.HOME),
          },
        ]}
      />
      {isDeleteDialogOpen && <DeleteDialogBox onClose={closeDeleteDialog} />}
    </>
  );
};

export default Step1;
