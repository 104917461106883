import React, {useContext, useMemo} from 'react';
import {MIGRATION_STAGE} from 'components/AppMigration/constants';
import Home from 'components/AppMigration/Home';
import Step1 from 'components/AppMigration/Step1';
import Step2 from 'components/AppMigration/Step2';
import Deleted from 'components/AppMigration/Deleted';
import Completed from 'components/AppMigration/Completed';
import {MigrationContext} from 'components/AppMigration/contexts';

const MigrationStage = () => {
  const {migrationStage} = useContext(MigrationContext);

  const Stage = useMemo(() => {
    switch (migrationStage) {
      case MIGRATION_STAGE.HOME:
        return Home;
      case MIGRATION_STAGE.STEP1:
        return Step1;
      case MIGRATION_STAGE.STEP2:
        return Step2;
      case MIGRATION_STAGE.DELETED:
        return Deleted;
      case MIGRATION_STAGE.COMPLETED:
        return Completed;
      default:
        return Home;
    }
  }, [migrationStage]);

  return <Stage />;
};

export default MigrationStage;
