export const CUSTOM_ACTIVITY_REGEX =
  /custom-activity\/(?<token>[a-zA-Z0-9-_]+)\/add/;

export const ID_REGEX =
  /[a-zA-Z0-9-_]+\/climate-partner\/(?<id>[a-zA-Z0-9-_]+)/;

export enum QR_CODE_ACTIONS {
  CUSTOM_ACTIVITY = 'custom-activity',
  VIEW_OFFERS = 'view-offers',
  TRANSFER_COINS = 'transfer-coin',
  ATTACH_WALLET = 'attach-wallet',
  SEND_COINS = 'send-coins',
  MIGRATE_USER = 'migrate-user',
}

export type QR_CODE_ACTIONS_KEYS = keyof typeof QR_CODE_ACTIONS;
export type QR_CODE_ACTIONS_VALUES =
  typeof QR_CODE_ACTIONS[QR_CODE_ACTIONS_KEYS];

export type QRCodeDetails = {
  app: string;
  type: string;
  token: string;
  locked: boolean;
  protected: boolean;
  payload: {
    action: QR_CODE_ACTIONS;
    params: {
      climate_partner_id?: number;
      wallet_id: string;
      token: string;
    };
  };
  metadata: {
    name: string;
  };
};
