import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useState,
} from 'react';
import {
  MIGRATION_LINK_STORAGE_KEY,
  MIGRATION_STAGE,
  MIGRATION_STAGE_STORAGE_KEY,
} from 'components/AppMigration/constants';

const defaultMigrationStage =
  (localStorage.getItem(MIGRATION_STAGE_STORAGE_KEY) as MIGRATION_STAGE) ||
  MIGRATION_STAGE.HOME;

const savedMigrationLink =
  (localStorage.getItem(MIGRATION_LINK_STORAGE_KEY) as string) || '';

export const MigrationContext = createContext({
  migrationStage: defaultMigrationStage,
  saveMigrationStage: (stage: MIGRATION_STAGE) => {},
  migrationLink: savedMigrationLink,
  saveMigrationLink: (link: string) => {},
});

export const MigrationProvider = (props: PropsWithChildren<{}>) => {
  const [migrationStage, setMigrationStage] = useState(defaultMigrationStage);
  const [migrationLink, setMigrationLink] = useState(savedMigrationLink);

  const saveMigrationStage = useCallback((stage: MIGRATION_STAGE) => {
    setMigrationStage(stage);
    localStorage.setItem(MIGRATION_STAGE_STORAGE_KEY, stage);
  }, []);

  const saveMigrationLink = useCallback((link: string) => {
    setMigrationLink(link);
    localStorage.setItem(MIGRATION_LINK_STORAGE_KEY, link);
  }, []);

  return (
    <MigrationContext.Provider
      value={{
        migrationStage,
        saveMigrationStage,
        migrationLink,
        saveMigrationLink,
      }}>
      {props.children}
    </MigrationContext.Provider>
  );
};
