export enum MIGRATION_STAGE {
  HOME = 'HOME',
  STEP1 = 'STEP1',
  STEP2 = 'STEP2',
  DELETED = 'DELETED',
  COMPLETED = 'COMPLETED',
}

export const MIGRATION_STAGE_STORAGE_KEY = 'migrationStage';

export const MIGRATION_LINK_STORAGE_KEY = 'migrationLink';
