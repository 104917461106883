import React, {useMemo} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import Button from 'components/Button/Button';
import CtaLink from 'components/CtaLink/CtaLink';
import PromptWrapper from './PromptWrapper/PromptWrapper';

const Footer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 20px;
`;

const LinkText = styled(CtaLink)`
  margin-top: 20px;
  margin-bottom: 0;
`;

type MoreInfo = {
  text?: string;
  link?: string;
  onClick?: (...data: any) => any;
} | null;

interface PromptModalProps {
  title?: string | JSX.Element;
  textContent?: string | JSX.Element;
  isVisible?: boolean;
  image?: string;
  isButtonLoading?: boolean;
  btnText?: string;
  moreInfo?: MoreInfo | Array<MoreInfo>;
  extraContent?: React.ReactNode;
  onBtnClick?: (...data: any) => any;
  onClose?: (...data: any) => any;
}

const PromptModal = (props: PromptModalProps) => {
  const {
    title,
    textContent,
    isVisible,
    image,
    isButtonLoading,
    btnText,
    onBtnClick,
    moreInfo,
    extraContent,
    onClose,
  } = props;

  const moreInfoContent = useMemo(
    () => (moreInfo ? (moreInfo instanceof Array ? moreInfo : [moreInfo]) : []),
    [moreInfo],
  );

  return (
    <PromptWrapper
      title={title}
      textContent={textContent}
      image={image}
      isVisible={isVisible}
      onClose={onClose}>
      <Footer>
        <div>
          {btnText && (
            <Button onClick={onBtnClick} isLoading={isButtonLoading}>
              {btnText}
            </Button>
          )}
          {moreInfoContent.map((info, index) => (
            <React.Fragment key={index}>
              {info &&
                (info.link ? (
                  <Link to={info.link} className="link-base">
                    <LinkText noMargin>{info.text}</LinkText>
                  </Link>
                ) : (
                  <LinkText
                    noMargin
                    onClick={() => info.onClick && info.onClick()}>
                    {info.text}
                  </LinkText>
                ))}
            </React.Fragment>
          ))}
          {extraContent && extraContent}
        </div>
      </Footer>
    </PromptWrapper>
  );
};

export default PromptModal;
