import {useCallback} from 'react';

import {NATIVE_EVENTS, NATIVE_MESSAGES} from 'constants/native-events';
import {useNativeListener} from 'hooks/use-native-listener';
import {useUserProfile} from 'hooks/use-user-profile';
import {deleteAccount} from 'services/account';
import {sendMessageToNativeApp} from 'services/native-api';

interface DeleteAccountProps {
  onNewToken?: () => void;
}

const useAccountDeletion = (props: DeleteAccountProps) => {
  const {onNewToken} = props;
  const {userProfile} = useUserProfile();

  const deleteUserAccount = useCallback(async () => {
    await deleteAccount({id: userProfile!.user!.id});
    const message = {
      type: NATIVE_MESSAGES.REQUEST_NEW_TOKEN,
    };
    sendMessageToNativeApp({message});
  }, [userProfile]);

  const handleNewToken = useCallback(() => {
    onNewToken && onNewToken();
  }, [onNewToken]);

  useNativeListener({
    event: NATIVE_EVENTS.NEW_TOKEN,
    callback: handleNewToken,
  });

  return {deleteUserAccount};
};

export default useAccountDeletion;
