import React, {useCallback, useEffect} from 'react';

import {useBoolean} from 'hooks/utils/use-boolean';
import {useTranslations} from 'hooks/use-translations';
import {useTokenTranslation} from 'hooks/use-token-translation';
import {getDeviceLanguage} from 'utils/language';
import {openLink} from 'utils/dom';

import PromptModal from 'components/PromptModal/PromptModal';

import banner from './images/banner.svg';

const deviceLanguage = getDeviceLanguage();

const LOCAL_STORAGE_KEY = 'aachen-survey-state';

enum SURVEY_STATE {
  RATED = 'RATED',
  LATER = 'LATER',
  NEVER = 'NEVER',
}

const COINS_PER_SURVEY = 3;

const surveyLinks = {
  en: 'https://app.sli.do/event/xr4NtrDuH1GeX6FBdDzw5N',
  de: 'https://app.sli.do/event/8ss11E3Lecdj6aoXiW4R1a',
};
// @ts-ignore
const surveyLink = surveyLinks[deviceLanguage] || surveyLinks.de;

const START_DATE = new Date('2024-09-01');
const END_DATE = new Date('2024-10-21');
const isActive = new Date() >= START_DATE && new Date() <= END_DATE;

let SURVEY_INITIATED = false;

const AachenSurvey = () => {
  const {translate} = useTranslations();
  const {getTokenText} = useTokenTranslation();
  const [isSurveyVisible, openSurvey, closeSurvey] = useBoolean(false);

  useEffect(
    () => {
      if (!isActive) {
        return;
      }

      const surveyState = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (
        !surveyState ||
        (!SURVEY_INITIATED && surveyState === SURVEY_STATE.LATER)
      ) {
        openSurvey();
      }

      function onVisibilityChange() {
        if (document.visibilityState !== 'visible') {
          return;
        }

        const newSurveyState = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (
          [SURVEY_STATE.RATED, SURVEY_STATE.NEVER].includes(
            newSurveyState as SURVEY_STATE,
          )
        ) {
          return;
        }

        openSurvey();
      }

      document.addEventListener('visibilitychange', onVisibilityChange);
      SURVEY_INITIATED = true;

      return () => {
        document.removeEventListener('visibilitychange', onVisibilityChange);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleClose = useCallback(
    (state: SURVEY_STATE) => {
      localStorage.setItem(LOCAL_STORAGE_KEY, state);
      closeSurvey();
    },
    [closeSurvey],
  );

  const handleRate = useCallback(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, SURVEY_STATE.RATED);

    openLink(surveyLink);

    closeSurvey();
  }, [closeSurvey]);

  if (!isActive) {
    return null;
  }

  return (
    <PromptModal
      isVisible={isSurveyVisible}
      image={banner}
      title={translate('sdk.web.aachen.survey.title')}
      textContent={translate('sdk.web.aachen.survey.text', [
        {
          key: '{nr}',
          value: COINS_PER_SURVEY,
        },
        {
          key: '{tokens}',
          value: getTokenText(COINS_PER_SURVEY),
        },
      ])}
      btnText={translate('sdk.web.aachen.survey.cta.rate')}
      onBtnClick={handleRate}
      onClose={() => handleClose(SURVEY_STATE.LATER)}
      moreInfo={[
        {
          text: translate('sdk.web.aachen.survey.cta.later'),
          onClick: () => handleClose(SURVEY_STATE.LATER),
        },
        {
          text: translate('sdk.web.aachen.survey.cta.never'),
          onClick: () => handleClose(SURVEY_STATE.NEVER),
        },
      ]}
    />
  );
};

export default AachenSurvey;
