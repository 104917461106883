import React, {useCallback, useContext} from 'react';

import useAccountDeletion from 'hooks/auth/use-account-deletion';
import {useTranslations} from 'hooks/use-translations';

import DialogBox from 'components/DialogBox/DialogBox';
import {MigrationContext} from 'components/AppMigration/contexts';
import {MIGRATION_STAGE} from 'components/AppMigration/constants';

interface DeleteDialogBoxProps {
  onClose: () => void;
}

const DeleteDialogBox = (props: DeleteDialogBoxProps) => {
  const {onClose} = props;
  const {translate} = useTranslations();
  const {saveMigrationStage} = useContext(MigrationContext);
  const {deleteUserAccount} = useAccountDeletion({});

  const handleDelete = useCallback(async () => {
    await deleteUserAccount();
    saveMigrationStage(MIGRATION_STAGE.DELETED);
  }, [deleteUserAccount, saveMigrationStage]);

  const handleConfirmation = useCallback(
    (shouldDelete: boolean) => {
      if (!shouldDelete) {
        return onClose();
      }

      return handleDelete();
    },
    [onClose, handleDelete],
  );

  return (
    <DialogBox
      isLoading={false}
      title={translate('sdk.web.migration.delete.title')}
      promptMessage={translate('sdk.web.migration.delete.subtitle')}
      yesText={translate('sdk.web.migration.delete.cta')}
      noText={translate('sdk.web.dialog.box.cancel')}
      onConfirmation={handleConfirmation}
    />
  );
};

export default DeleteDialogBox;
