import React, {useContext} from 'react';

import {useTranslations} from 'hooks/use-translations';
import {SettingsContext} from 'contexts/SettingsContext';

import PromptModal from 'components/PromptModal/PromptModal';

const Deleted = () => {
  const {translate} = useTranslations();
  const {organizationConfig, appName} = useContext(SettingsContext);

  const migrationConfig = organizationConfig.featuresConfig.migration;

  return (
    <PromptModal
      isVisible
      image={migrationConfig.images.migrationDeleteImage}
      title={translate('sdk.web.migration.delete.success', {
        key: '{appName}',
        value: appName,
      })}
      textContent={translate('sdk.web.migration.delete.success.subtitle', {
        key: '{appName}',
        value: appName,
      })}
    />
  );
};

export default Deleted;
