interface UpdateMetaTagContent {
  name: string;
  key: string;
  value: string;
}

export const updateMetaTagContent = ({
  name,
  key,
  value,
}: UpdateMetaTagContent) => {
  const metaTag = document.querySelector(
    `meta[name='${name}']`,
  ) as HTMLMetaElement;
  if (!metaTag) {
    return;
  }

  const splitContent = metaTag.content.split(',');
  const contentKeys: Record<string, string> = {};

  splitContent.forEach((content) => {
    const [contentKey, contentValue] = content.split('=');
    contentKeys[contentKey.trim()] = contentValue;
  });

  contentKeys[key] = value;

  const updatedContent = Object.keys(contentKeys)
    .map((contentKey) => `${contentKey}=${contentKeys[contentKey]}`)
    .join(', ');

  metaTag.setAttribute('content', updatedContent);
};

export const openLink = (url: string, target = '_blank') => {
  const link = document.createElement('a');
  link.href = url;
  link.target = target; // Optional
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
