import React, {useContext, useMemo} from 'react';
import {SettingsContext} from 'contexts/SettingsContext';
import {MigrationProvider} from 'components/AppMigration/contexts';
import MigrationStage from 'components/AppMigration/MigrationStage';

const AppMigration = () => {
  const {organizationConfig} = useContext(SettingsContext);

  const showMigration = useMemo(
    () =>
      organizationConfig.featuresConfig?.migration?.enabled &&
      new Date() >
        new Date(organizationConfig.featuresConfig.migration.CUT_OFF_DATE),
    [organizationConfig],
  );

  if (!showMigration) {
    return null;
  }

  return (
    <MigrationProvider>
      <MigrationStage />
    </MigrationProvider>
  );
};

export default AppMigration;
