import React, {useCallback, useContext} from 'react';

import {SettingsContext} from 'contexts/SettingsContext';
import {MigrationContext} from 'components/AppMigration/contexts';
import {MIGRATION_STAGE} from 'components/AppMigration/constants';
import {useTranslations} from 'hooks/use-translations';
import {useBoolean} from 'hooks/utils/use-boolean';
import {useToast} from 'hooks/use-toast';
import {initiateAccountTransfer} from 'services/migrations';
import {openLink} from 'utils/dom';
import {MIGRATION_STATUS} from 'types/Migration';

import DeleteDialogBox from 'components/AppMigration/DeleteDialogBox';
import PromptModal from 'components/PromptModal/PromptModal';

const Step2 = () => {
  const {translate} = useTranslations();
  const toast = useToast();
  const {organizationConfig, appName} = useContext(SettingsContext);
  const {saveMigrationLink, saveMigrationStage} = useContext(MigrationContext);
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] =
    useBoolean(false);
  const [isInitiating, startInitiation, stopInitiation] = useBoolean(false);

  const migrationConfig = organizationConfig.featuresConfig.migration;

  const handleTransferAccount = useCallback(
    async (skipLinkOpening?: boolean) => {
      try {
        startInitiation();
        const migrationData = await initiateAccountTransfer({
          app: migrationConfig.destination.app,
        });

        saveMigrationLink(migrationData.link);

        if (!skipLinkOpening) {
          // Use setTimeout to open the link in the next tick to bypass Safari's popup blocker
          setTimeout(() => {
            openLink(migrationData.link, '_self');
          }, 0);
        }

        if (migrationData.status !== MIGRATION_STATUS.COMPLETED) {
          setTimeout(() => {
            handleTransferAccount(true);
          }, 2000);
          return;
        }

        const moveToNextStep = true;
        if (moveToNextStep) {
          setTimeout(() => {
            saveMigrationStage(MIGRATION_STAGE.COMPLETED);
          }, 500);
        }
      } catch (e: any) {
        alert('Error');
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        const message = translate(
          errorKey || 'sdk.web.migration.steps.2.error.fallback',
        );
        toast.error(message);
      } finally {
        stopInitiation();
      }
    },
    [
      migrationConfig,
      saveMigrationStage,
      saveMigrationLink,
      startInitiation,
      stopInitiation,
      toast,
      translate,
    ],
  );

  return (
    <>
      <PromptModal
        title={translate('sdk.web.migration.steps.2.title')}
        textContent={translate('sdk.web.migration.steps.2.subtitle', [
          {
            key: '{destinationAppName}',
            value: migrationConfig.destination.appLabel,
          },
          {
            key: '{appName}',
            value: appName,
          },
        ])}
        isVisible={true}
        image={migrationConfig.images.step2Image}
        btnText={translate('sdk.web.migration.steps.2.cta', {
          key: '{destinationAppName}',
          value: migrationConfig.destination.appLabel,
        })}
        onBtnClick={() => handleTransferAccount()}
        isButtonLoading={isInitiating}
        moreInfo={[
          {
            text: translate('sdk.web.migration.cta.delete'),
            onClick: openDeleteDialog,
          },
          {
            text: translate('sdk.web.migration.steps.cancel'),
            onClick: () => saveMigrationStage(MIGRATION_STAGE.HOME),
          },
        ]}
      />
      {isDeleteDialogOpen && <DeleteDialogBox onClose={closeDeleteDialog} />}
    </>
  );
};

export default Step2;
