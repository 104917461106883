import React, {useState} from 'react';

import {useTranslations} from 'hooks/use-translations';
import useAccountDeletion from 'hooks/auth/use-account-deletion';

import DialogBox from 'components/DialogBox/DialogBox';

interface DeleteAccountDialogBoxProps {
  isOpen: boolean;
  onClose: () => void;
}

const DeleteAccountDialogBox = (props: DeleteAccountDialogBoxProps) => {
  const {isOpen, onClose} = props;
  const {translate} = useTranslations();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleNewToken = async () => {
    setIsDeleting(false);
  };

  const {deleteUserAccount} = useAccountDeletion({onNewToken: handleNewToken});

  const handleDeleteAccountConfirmation = async (
    shouldDeleteAccount: boolean,
  ) => {
    if (!shouldDeleteAccount) {
      onClose();
      return;
    }

    try {
      setIsDeleting(true);
      await deleteUserAccount();
    } catch (e: any) {
      setIsDeleting(false);
    }
  };

  return (
    <React.Fragment>
      {isOpen && (
        <DialogBox
          title={translate('sdk.web.account.delete.title')}
          promptMessage={translate('sdk.web.account.delete.prompt')}
          onConfirmation={handleDeleteAccountConfirmation}
          yesText={translate('sdk.web.dialog.box.confirm')}
          noText={translate('sdk.web.dialog.box.cancel')}
          isLoading={isDeleting}
        />
      )}
    </React.Fragment>
  );
};

export default DeleteAccountDialogBox;
