import React, {useContext} from 'react';

import {MIGRATION_STAGE} from 'components/AppMigration/constants';
import {SettingsContext} from 'contexts/SettingsContext';
import {MigrationContext} from 'components/AppMigration/contexts';
import {useTranslations} from 'hooks/use-translations';
import {useBoolean} from 'hooks/utils/use-boolean';
import {openLink} from 'utils/dom';

import PromptModal from 'components/PromptModal/PromptModal';
import DeleteDialogBox from 'components/AppMigration/DeleteDialogBox';

const Home = () => {
  const {translate} = useTranslations();
  const {organizationConfig} = useContext(SettingsContext);
  const {saveMigrationStage} = useContext(MigrationContext);
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] =
    useBoolean(false);

  const migrationConfig = organizationConfig.featuresConfig.migration;
  const migrationTranslations = organizationConfig.translations.migration;

  return (
    <>
      <PromptModal
        title={translate(migrationTranslations.prompt.title)}
        textContent={translate(migrationTranslations.prompt.subtitle)}
        isVisible={true}
        image={migrationConfig.images.banner}
        btnText={translate('sdk.web.migration.cta.move')}
        onBtnClick={() => saveMigrationStage(MIGRATION_STAGE.STEP1)}
        moreInfo={[
          {
            text: translate('sdk.web.migration.cta.delete'),
            onClick: openDeleteDialog,
          },
          {
            text: translate('sdk.web.migration.cta.info'),
            onClick: () => openLink(migrationConfig.moreInfoUrl),
          },
        ]}
      />
      {isDeleteDialogOpen && <DeleteDialogBox onClose={closeDeleteDialog} />}
    </>
  );
};

export default Home;
