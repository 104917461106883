import {fetchAPiClient} from 'services/api';
import {MigrationCompleteData, MigrationData} from 'types/Migration';

const defaultClient = fetchAPiClient({subdomain: 'sst'});

type InitiateTransferParams = {
  app: string;
};

export const initiateAccountTransfer = async (
  data: InitiateTransferParams,
): Promise<MigrationData> => {
  const client = defaultClient;
  const url = '/user/migration/link';
  return client.post(url, data);
};

type CompleteTransferParams = {
  token: string;
};
export const completeAccountTransfer = async (
  data: CompleteTransferParams,
): Promise<MigrationCompleteData> => {
  const client = defaultClient;
  const url = '/user/migration/process';
  return client.post(url, data);
};
