import React, {useCallback, useMemo, useRef} from 'react';
import styled from 'styled-components';
import {useFormik} from 'formik';
import * as yup from 'yup';
import pick from 'lodash/pick';
import get from 'lodash/get';

import {CACHE_KEYS} from 'constants/cache-keys';
import {useForm} from 'hooks/forms/use-form';
import {useFetcher} from 'hooks/use-fetcher';
import {useFormValidation} from 'hooks/forms/use-form-validation';
import {useToast} from 'hooks/use-toast';
import {useBoolean} from 'hooks/utils/use-boolean';
import useElementTranslation from '../hooks/use-element-translation';
import {
  connect,
  disconnect,
  getEnergyProviders,
  getHouseholdSettings,
  getMeteringSettingsOptions,
  getUserMeter,
} from 'services/metering';
import {toBoolean} from 'utils/strings';
import {ElectricityProvider, METERING_ELEMENTS} from 'types/Metering';
import {ELEMENT_SCREENS, ElementScreenProps} from '../types';

import Button from 'components/Button/Button';
import CtaLink from 'components/CtaLink/CtaLink';
import CopyText from 'components/Text/CopyText/CopyText';
import Radio from 'components/Form/Radio';
import DialogBox from 'components/DialogBox/DialogBox';
import {Title} from 'components/PromptModal/PromptWrapper/PromptWrapper';
import PromptModal from 'components/PromptModal/PromptModal';
import AutoComplete from 'components/Form/AutoComplete';
import Dropdown from 'components/Form/Dropdown';
import FormGroup from 'components/Form/FormGroup';

import AboutElement from './AboutElement';
import Label from './Label';
import Input from './Input';

import {ReactComponent as InfoIcon} from 'icons/info-alt.svg';

const Wrapper = styled.div`
  padding: 0 20px 5px;

  .e-settings {
    &__icon {
      margin-bottom: 20px;
    }

    &__description {
      padding: 0 20px;
      margin-bottom: 40px;
    }

    &__form {
      text-align: left;
    }

    &__cta {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 60px;
    }

    &__info {
      margin-left: 5px;
      width: 24px;
      display: flex;
      fill: ${(props) =>
        props.theme.components.metering?.infoIconColor ||
        props.theme.colors.primary};
    }

    &__btn {
      margin-bottom: 20px;
      width: 100%;
    }
  }
`;

const elementFieldsMap: Record<METERING_ELEMENTS, Array<string>> = {
  [METERING_ELEMENTS.WATER]: [
    'people',
    // 'size',
    // 'appliances_count',
    'energy_meter_id',
  ],
  [METERING_ELEMENTS.ELECTRICITY]: [
    'size',
    'people',
    'appliances_count',
    'electric_water_heating',
    'energy_type',
    'energy_meter_id',
    'energy_provider_id',
    'uses_heat_pump',
    'building_type',
    'heat_pump_type',
  ],
  [METERING_ELEMENTS.GAS]: [
    'size',
    'people',
    // 'appliances_count',
    'gas_water_heating',
    // 'energy_type',
    'energy_meter_id',
    'energy_provider_id',
    'heat_source_type',
    'heat_unit_type',
    'building_type',
  ],
};

const Settings = (props: ElementScreenProps) => {
  const {element, elementData, stats, onComplete} = props;
  const translate = useElementTranslation({element});
  const formRef = useRef<HTMLFormElement>(null);
  const {getKeyDownListener} = useForm();
  const {apiErrors, validate} = useFormValidation();
  const toast = useToast();

  const [isResetDialogVbl, openResetDialog, closeResetDialog] = useBoolean();
  const [isSubmitting, startSubmitting, stopSubmitting] = useBoolean();
  const [isResetting, startResetting, stopResetting] = useBoolean();

  const [isAboutAppliancesVbl, showAboutAppliances, hideAboutAppliances] =
    useBoolean();
  const [isAboutElementVbl, showAboutElement, hideAboutElement] =
    useBoolean(false);

  const filterOutFields = useCallback(
    (fields: Record<string, any>) => {
      return pick(fields, elementFieldsMap[element]);
    },
    [element],
  );

  const defaultValues = useMemo(() => {
    return {
      ...filterOutFields({
        type: element,
        energy_provider_id: '',
        energy_meter_id: '',
      }),
      user_household_settings: filterOutFields({
        people: '',
        size: '',
        appliances_count: '',
        electric_water_heating: false,
        gas_water_heating: false,
        energy_type: 'mix',
        uses_heat_pump: false,
        building_type: '',
        heat_pump_type: '',
        heat_source_type: 'gas',
        heat_unit_type: '',
      }),
    };
  }, [filterOutFields, element]);

  const {validationSchema} = useMemo(() => {
    const getRequiredError = (label: string) =>
      translate('sdk.web.error.field.required', {
        key: '{field}',
        value: translate(label),
      });

    const validationSchema = yup.object().shape({
      ...filterOutFields({
        energy_provider_id: yup.number().nullable(true),
        energy_meter_id: yup.string().nullable(true),
      }),
      user_household_settings: yup.object().shape(
        filterOutFields({
          people: yup
            .number()
            .required(getRequiredError('sdk.web.meter.settings.household')),
          size: yup
            .number()
            .required(getRequiredError('sdk.web.meter.settings.apt.size')),
          appliances_count: yup
            .number()
            .required(getRequiredError('sdk.web.meter.settings.appliances')),
          energy_type: yup.string(),
          heat_source_type: yup.string(),
          heat_unit_type: yup.string(),
          electric_water_heating: yup.boolean(),
          gas_water_heating: yup.boolean(),
          uses_heat_pump: yup.boolean().nullable(true),
          building_type: yup.string().nullable(true),
          heat_pump_type: yup.string().nullable(true),
        }),
      ),
    });

    return {validationSchema};
  }, [translate, filterOutFields]);

  const {data: initialValues, updateDataById: updateUserMeterCache} =
    useFetcher({
      fetcher: stats?.connected ? getUserMeter : getHouseholdSettings,
      params: {
        type: element,
      },
      initialValue: defaultValues,
      key: CACHE_KEYS.METERING[element].USER_METER,
    });

  const {data: allProviders, fetchData: fetchProviders} = useFetcher({
    fetcher: getEnergyProviders,
    params: {
      type: element,
      include: initialValues?.energy_provider_id,
    },
    initialValue: [],
    key: CACHE_KEYS.METERING[element].ENERGY_PROVIDERS,
    preventFetch: stats?.connected ? !initialValues?.id : false,
  });

  const energyProviders = useMemo(
    () =>
      allProviders.map(({id, name}: ElectricityProvider) => ({
        id,
        label: name,
        value: id,
      })),
    [allProviders],
  );

  const handleProviderSearch = useCallback(
    ({query}: {query: string}) => {
      fetchProviders({
        search: query,
        type: element,
      });
    },
    [fetchProviders, element],
  );

  const onSubmit = useCallback(
    async (data: typeof initialValues) => {
      data.user_household_settings.electric_water_heating = toBoolean(
        data.user_household_settings.electric_water_heating,
      );
      data.user_household_settings.gas_water_heating = toBoolean(
        data.user_household_settings.gas_water_heating,
      );
      data.user_household_settings.uses_heat_pump = toBoolean(
        data.user_household_settings.uses_heat_pump,
      );

      try {
        startSubmitting();
        const response = await connect({
          ...data,
          driver: 'manual',
          type: element,
        });

        updateUserMeterCache({updates: response});

        onComplete(
          !stats?.connected
            ? {nextScreen: ELEMENT_SCREENS.SETTINGS_CONFIRMATION}
            : undefined,
        );
      } catch (e: any) {
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        const message = translate(
          errorKey || 'sdk.web.meter.settings.error.fallback',
        );
        toast.error(message);
      } finally {
        stopSubmitting();
      }
    },
    [
      element,
      stats,
      onComplete,
      startSubmitting,
      stopSubmitting,
      updateUserMeterCache,
      toast,
      translate,
    ],
  );

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validate,
  });

  const handleResetConfirmation = useCallback(
    async (shouldReset: boolean) => {
      if (!shouldReset) {
        closeResetDialog();
        return;
      }

      try {
        startResetting();
        await disconnect({type: element});
        onComplete();
      } catch (e: any) {
        const errorKey = e.response ? e.response?.data?.errorKey : '';
        const message = translate(
          errorKey || 'sdk.web.meter.settings.reset.error.fallback',
        );
        toast.error(message);
      } finally {
        stopResetting();
      }

      closeResetDialog();
    },
    [
      element,
      toast,
      translate,
      startResetting,
      stopResetting,
      closeResetDialog,
      onComplete,
    ],
  );

  const fields = useMemo(
    () =>
      getMeteringSettingsOptions({
        translate,
        onAppliancesInfoClick: showAboutAppliances,
        onEnergyProviderSearch: handleProviderSearch,
        energyProviders,
      }),
    [translate, energyProviders, showAboutAppliances, handleProviderSearch],
  );

  return (
    <>
      <Wrapper className="e-settings">
        <Title>{translate('sdk.web.meter.settings.title')}</Title>
        <img
          className="e-settings__icon"
          src={elementData.banner}
          alt="banner"
        />
        <div className="e-settings__description">
          <CopyText>{translate('sdk.web.meter.settings.desc')}</CopyText>
        </div>
        <form
          className="e-settings__form"
          ref={formRef}
          onSubmit={formik.handleSubmit}>
          {fields.map((field) => {
            if (field.hideOnConnection && stats) {
              return null;
            }

            if (field.dependsOn) {
              const dependsOn = field.dependsOn as Array<{
                name: string;
                value: any;
                applicableElements?: Array<METERING_ELEMENTS>;
                transformer?: (value: any) => any;
              }>;
              const renderFields = dependsOn.reduce(
                (shouldRender, validation) => {
                  if (!shouldRender) {
                    return false;
                  }

                  if (
                    validation.applicableElements &&
                    !validation.applicableElements.includes(element)
                  ) {
                    return true;
                  }

                  let dependsOnValue = get(formik.values, validation.name);
                  if (validation.transformer) {
                    dependsOnValue = validation.transformer(dependsOnValue);
                  }

                  return dependsOnValue === validation.value;
                },
                true,
              );

              if (!renderFields) {
                return null;
              }
            }

            const defaultOptions = {
              name: field.key,
              value: get(formik.values, field.key),
              onChange: formik.handleChange,
              formik,
              apiErrors,
            };
            if (field.type === 'text') {
              defaultOptions.value = defaultOptions.value || '';
            }

            return (
              <>
                {get(
                  validationSchema.fields,
                  field.validationKey || field.key,
                ) && (
                  <FormGroup>
                    <Label as="div">
                      {field.label.onInfoClick ? (
                        <>
                          <span>{translate(field.label.key)} </span>
                          <span
                            className="e-settings__info"
                            onClick={field.label.onInfoClick}>
                            <InfoIcon width="100%" />
                          </span>
                        </>
                      ) : (
                        translate(field.label.key, undefined, {
                          capitalizeElement: field.label.capitalizeElement,
                        })
                      )}
                    </Label>
                    <>
                      {field.radioOptions ? (
                        <Radio
                          list={field.radioOptions.list}
                          {...defaultOptions}
                        />
                      ) : field.dropdownOptions ? (
                        <Dropdown
                          list={field.dropdownOptions.list}
                          optional={true}
                          {...defaultOptions}
                        />
                      ) : field.autoCompleteOptions ? (
                        <AutoComplete
                          defaultValue={get(initialValues, field.key)}
                          optional={true}
                          list={field.autoCompleteOptions.list}
                          onSearch={field.autoCompleteOptions.onSearch}
                          {...defaultOptions}
                        />
                      ) : (
                        <Input
                          placeholder=""
                          type={field.type}
                          {...field.inputOptions}
                          enterkeyhint={field.enterKeyHint}
                          onKeyDown={getKeyDownListener({
                            form: formRef.current,
                          })}
                          {...defaultOptions}
                        />
                      )}
                    </>
                  </FormGroup>
                )}
              </>
            );
          })}
          <div className="e-settings__cta">
            <div className="e-settings__btn">
              <Button isLoading={isSubmitting}>
                {translate('sdk.web.meter.settings.cta')}
              </Button>
            </div>
            {stats?.connected && (
              <>
                <CtaLink onClick={openResetDialog}>
                  {translate('sdk.web.meter.settings.reset.reading')}
                </CtaLink>
              </>
            )}
            <CtaLink onClick={showAboutElement}>
              {translate('sdk.web.meter.settings.learn.more')}
            </CtaLink>
          </div>
        </form>
        <PromptModal
          isVisible={isAboutAppliancesVbl}
          title={translate('sdk.web.meter.appliances.about.title')}
          textContent={translate('sdk.web.meter.appliances.about.content')}
          btnText={translate('sdk.web.dialog.box.ok')}
          onBtnClick={hideAboutAppliances}
          onClose={hideAboutAppliances}
        />
        <AboutElement
          isVisible={isAboutElementVbl}
          element={element}
          onClose={hideAboutElement}
        />
      </Wrapper>

      {isResetDialogVbl && (
        <DialogBox
          title={translate('sdk.web.meter.settings.reset.reading.prompt')}
          promptMessage={translate(
            'sdk.web.meter.settings.reset.reading.message',
          )}
          yesText={translate('sdk.web.dialog.box.confirm')}
          noText={translate('sdk.web.dialog.box.cancel')}
          isLoading={isResetting}
          onConfirmation={handleResetConfirmation}
        />
      )}
    </>
  );
};

export default Settings;
