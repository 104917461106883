import React, {useContext} from 'react';

import {useTranslations} from 'hooks/use-translations';
import {SettingsContext} from 'contexts/SettingsContext';
import {MigrationContext} from 'components/AppMigration/contexts';
import {openLink} from 'utils/dom';

import PromptModal from 'components/PromptModal/PromptModal';

const Completed = () => {
  const {translate} = useTranslations();
  const {organizationConfig, appName} = useContext(SettingsContext);
  const {migrationLink} = useContext(MigrationContext);

  const migrationConfig = organizationConfig.featuresConfig.migration;

  return (
    <PromptModal
      isVisible
      image={migrationConfig.images.migrationSuccessImage}
      title={translate('sdk.web.migration.success.title', {
        key: '{appName}',
        value: appName,
      })}
      textContent={translate(
        organizationConfig.translations.migration?.success.subtitle,
      )}
      onBtnClick={() => openLink(migrationLink, '_self')}
      btnText={translate('sdk.web.migration.success.cta', {
        key: '{destinationAppName}',
        value: migrationConfig.destination.appLabel,
      })}
    />
  );
};

export default Completed;
