import {useCallback, useContext} from 'react';

import {NATIVE_MESSAGES} from 'constants/native-events';
import {AuthContext} from 'contexts/AuthContext';
import {sendMessageToNativeApp} from 'services/native-api';

const useAppAuthUpdate = () => {
  const {updateAuthToken} = useContext(AuthContext);

  const updateAppAuth = useCallback(
    ({token, uuid}: {token: string; uuid: string}) => {
      updateAuthToken({token});
      sendMessageToNativeApp({
        message: {
          type: NATIVE_MESSAGES.UPDATE_TOKEN,
          uuid,
          new_token: token,
        },
      });
    },
    [updateAuthToken],
  );

  return {
    updateAppAuth,
  };
};

export default useAppAuthUpdate;
