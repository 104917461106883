import React, {Suspense, useContext, useEffect} from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import routes from 'config/routes';
import {NATIVE_EVENTS, NATIVE_MESSAGES} from 'constants/native-events';
import {SettingsContext} from 'contexts/SettingsContext';
import useCacheBuster from 'hooks/app/use-cache-buster';
import useFeaturesSetup from 'hooks/app/use-features-setup';
import useNotificationsLoader from 'hooks/services/notifications/use-notifications-loader';
import {UserProfile} from 'services/user';
import {sendMessageToNativeApp} from 'services/native-api';
import {addBoldText} from 'utils/theme';

import NativeWrapper from 'components/NativeWrapper/NativeWrapper';
import AppTracker from 'components/AppTracker/AppTracker';
import AppPageTracker from 'components/AppTracker/AppPageTracker';
import PermissionsTracker from 'components/AppTracker/PermissionsTracker';
import QrScanner from 'components/QrScanner';
import TokenLoader from 'components/TokenLoader';

import Dashboard from './Dashboard/Dashboard';
import Settings from './Settings/Settings';
import Onboarding from './Onboarding/Onboarding';
import Co2Compensation from './Co2Compensation/Co2Compensation';
import AppMigration from 'components/AppMigration';

import CustomActivities from 'pages/CustomActivities/CustomActivities';
import Water from 'pages/Water';
import Co2Emissions from 'pages/Co2Emissions';
import Notifications from 'pages/Notifications';
import ClimateTreasure from 'pages/ClimateTreasure';

interface GlobalStyles {
  bgColor: string;
  fontFamily: string;
}

const GlobalStyle = createGlobalStyle<GlobalStyles>`
    body {
        background: ${(props) => props.bgColor};
        font-family: ${(props) => props.fontFamily};
    }
`;

const Wrapper = styled.div`
  // max-width: 450px;
  position: relative;
  margin: 0 auto;
  height: 100vh;
  overflow: scroll;
  color: ${(props) => props.theme.colors.black};

  img {
    max-width: 100%;
  }

  b {
    ${(props) =>
      addBoldText({
        props,
      })}
  }
`;

const Loading = () => <div>Loading...</div>;

interface RootPageProps {
  userProfile: UserProfile;
}

const RootPage = (props: RootPageProps) => {
  const {userProfile} = props;
  const {bodyBgColor, organizationConfig, isOnboardingComplete} =
    useContext(SettingsContext);
  useCacheBuster();
  useFeaturesSetup();
  useNotificationsLoader();

  useEffect(() => {
    if (isOnboardingComplete) {
      const message = {type: NATIVE_MESSAGES.ONBOARDING_COMPLETED};
      sendMessageToNativeApp({message});
    }
  }, [isOnboardingComplete]);

  return (
    <Wrapper>
      <GlobalStyle
        bgColor={bodyBgColor}
        fontFamily={organizationConfig.theme.font.primary.name}
      />
      <AppTracker user={userProfile.user}>
        <AppPageTracker>
          <PermissionsTracker>
            <NativeWrapper>
              <AppMigration />
              <Router>
                <Suspense fallback={<Loading />}>
                  <Switch>
                    <Route path="/onboarding">
                      <Onboarding />
                    </Route>
                    <Route path="/dashboard">
                      <Dashboard />
                    </Route>
                    <Route path={routes.SETTINGS.href}>
                      <Settings />
                    </Route>
                    <Route path={routes.CO2_COMPENSATION.href}>
                      <Co2Compensation />
                    </Route>
                    <Route path={routes.CUSTOM_ACTIVITIES.href}>
                      <CustomActivities />
                    </Route>
                    <Route path={routes.WATER.href}>
                      <Water />
                    </Route>
                    <Route path={routes.CO2_EMISSIONS.href}>
                      <Co2Emissions />
                    </Route>
                    <Route path={routes.NOTIFICATIONS.href}>
                      <Notifications />
                    </Route>
                    <Route path={routes.CLIMATE_TREASURE.href}>
                      <ClimateTreasure />
                    </Route>
                    <Route path="/">
                      <Onboarding />
                    </Route>
                  </Switch>
                  <QrScanner
                    qrEvent={NATIVE_EVENTS.GLOBAL_QRCODE_URL}
                    isOneTime={false}
                    preventListen={!isOnboardingComplete}
                  />
                  <TokenLoader />
                </Suspense>
              </Router>
            </NativeWrapper>
          </PermissionsTracker>
        </AppPageTracker>
      </AppTracker>
    </Wrapper>
  );
};

export default RootPage;
