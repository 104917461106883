export enum MIGRATION_STATUS {
  PENDING = 'pending',
  COMPLETED = 'completed',
}

export type MigrationData = {
  user_id: number;
  token: string;
  from_organisation_id: number;
  from_app: string;
  to_organisation_id: number;
  to_app: string;
  status: MIGRATION_STATUS;
  updated_at: string;
  created_at: string;
  id: number;
  link: string;
};

export type MigrationCompleteData = {
  data: MigrationData;
  token: {
    user_id: number;
    token: string;
    uuid: string;
    account_verified: boolean;
    admin_account_verified: boolean;
    completed_onboarding: boolean;
  };
};
